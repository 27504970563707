import React from 'react';
import priceDel from 'assets/images/price-del.png';
import milaProductWhite from 'assets/images/mila-product-white.png';
import milaProductWhiteWebp from 'assets/images/mila-product-white.png?as=webp';
import milaProductWhiteMobile from 'assets/images/hero_mila2.png';
import milaProductWhiteMobileWebp from 'assets/images/hero_mila2.png?as=webp';

import {
  CURRENCY_SIGN, EMOJI, PRODUCT_VARIANT_ABBREVIATIONS, PRODUCTS_ID_MAPPING, SUPPORTED_REGIONS,
} from '../../../shared/enums';
import { getTrue } from '../../../shared/utils';

/**
 * @param localization
 * @param pricesSchema
 * @param error
 * @param title
 * @param description
 * @param altStyle
 * @returns {null|JSX.Element}
 * @constructor
 */
const OrderBlockAlt = ({
  localization, pricesSchema, error, title, description, altStyle,
}) => {
  const sign = CURRENCY_SIGN[localization.region] || CURRENCY_SIGN.US;
  const unit = {
    price: pricesSchema[PRODUCT_VARIANT_ABBREVIATIONS.CRITTER_CUDDLER]?.UNIT_PRICEx1,
    discount_price: pricesSchema[PRODUCT_VARIANT_ABBREVIATIONS.CRITTER_CUDDLER]?.UNIT_DISCOUNT_PRICE,
  };
  const basicBreather = {
    price: pricesSchema[PRODUCT_VARIANT_ABBREVIATIONS.BASIC_BREATHER]?.FILTER_PRICE,
  };

  const style = localization.region !== SUPPORTED_REGIONS.SG ? {} : {
    marginBottom: '15px',
    boxShadow: '0 32px 44px rgb(0 0 0 / 5%)',
  };

  return error ? null : (
    <section className={`order-alt ${altStyle !== undefined ? altStyle : ''}`}>
      <div className='order-alt__inner'>
        <div className='container'>
          <div className='order-alt__content'>
            <h2 className='order-alt__title'>{title !== undefined ? title : 'Make Mila yours today.'}</h2>
            <div className='order-alt__description'>

              {description !== undefined ? (
                <p>
                  {description}
                </p>
              )
                : (
                  <p>
                    {' '}
                    With free shipping and a 30-day guarantee, you have nothing to lose. 😉
                    {' '}
                    <br />
                    Don’t let Mila be the one that got away.
                  </p>
                )}
            </div>
            <figure className='order-alt__image'>
              <picture>
                {altStyle !== undefined && <source srcSet={milaProductWhiteMobileWebp} type='image/webp' />}
                {altStyle !== undefined && <source srcSet={milaProductWhiteMobile} type='image/png' />}
                {altStyle === undefined && <source media='(min-width: 769px)' srcSet={milaProductWhiteWebp} type='image/webp' />}
                {altStyle === undefined && <source media='(min-width: 769px)' srcSet={milaProductWhite} type='image/png' />}
                {altStyle === undefined && <source media='(max-width: 768px)' srcSet={milaProductWhiteMobileWebp} type='image/webp' />}
                {altStyle === undefined && <source media='(max-width: 768px)' srcSet={milaProductWhiteMobile} type='image/png' />}
                <img src={milaProductWhite} alt='mila-box' />
              </picture>
            </figure>
          </div>
          <div className='order-alt__card'>
            <div className='order-alt__card-primary' style={style}>
              <h3 className='order-alt__card-title'>
                <strong>Purchase Mila</strong>
                {' '}
                for just
              </h3>
              <p className='order-alt__card-price'>
                <span className='order-alt__price-amount'>
                  {(getTrue(ENABLE_DISCOUNT) && unit.discount_price) && (
                    <span style={{
                      color: '#979797',
                      marginRight: '15px',
                      verticalAlign: 'middle',
                      overflow: 'hidden',
                      position: 'relative',
                      fontSize: '2.10rem',
                    }}
                    >
                      <img
                        style={{
                          position: 'absolute',
                          top: '5px',
                          transform: 'rotate(3.42deg)',
                          width: '100%',
                        }}
                        src={priceDel}
                        alt='old-price'
                      />
                      {sign}
                      {unit.price}
                    </span>
                  )}
                  {(getTrue(ENABLE_DISCOUNT) && unit.discount_price) ? sign : (<span className='currency-symbol'>{sign}</span>)}
                  {(getTrue(ENABLE_DISCOUNT) && unit.discount_price) ? unit.discount_price : unit.price}
                </span>
                Per unit
              </p>
              {localization.region !== SUPPORTED_REGIONS.SG && (
                <svg className='icon icon-plus'>
                  <use xlinkHref='#icon-plus' />
                </svg>
              )}
            </div>
            {localization.region !== SUPPORTED_REGIONS.SG && (
              <div className='order-alt__card-secondary'>
                <div className='order-alt__secondary-inner'>
                  <h3 className='order-alt__card-title'>
                    <strong>Get fresh filters delivered</strong>
                    Starting at
                  </h3>
                  <p className='order-alt__card-price'>
                    <span className='order-alt__price-amount'>
                      <span className='currency-symbol'>{sign}</span>
                      {basicBreather.price}
                    </span>
                    Every
                    6 months
                  </p>
                </div>
                <p className='order-alt__note'>
                  <strong>
                    Your first filter is free
                    {EMOJI.HUGGING_FACE}
                  </strong>
                  {' '}
                  and you can cancel any time.
                </p>
              </div>
            )}
            <a href='/buy' className='btn btn--order btn--fullwidth'>Order Now</a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default OrderBlockAlt;
